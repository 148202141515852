import { AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms/index.d';
import moment from 'moment';
import { Observable } from 'rxjs';

import {
    ApprovalStateType,
    CalendarEvent,
    CodeBookSpecificSettings,
    CourseUnit,
    DocumentState,
    EvaluationAttainmentWrapper,
    FlowState, LocalDateRange,
    StudyRightExtension,
} from '.';
import {
    Code,
    CourseUnitRealisation,
    EducationOptionAcceptanceType,
    Enrolment,
    UniversitySettings as GeneratedUniversitySettings,
    Location,
    OpenUniversityProduct,
    OrganisationRoleShare,
    PersonWithCourseUnitRealisationResponsibilityInfoType,
    PersonWithEducationResponsibilityInfoType,
    PersonWithModuleResponsibilityInfoType,
    PublicPerson,
    StudyRightState,
    TermRegistration,
} from './generated/common-backend';
import { OpenUniversityProductResultItem } from './generated/kori';

/**
 * Analogous to the built-in `Partial` utility type, with the difference that this type will also make all nested properties
 * optional. Courtesy of https://stackoverflow.com/a/47914631/2782785.
 */
export type DeepPartial<T> = {
    [P in keyof T]?: DeepPartial<T[P]>;
};

/**
 * A utility type which allows making some mandatory properties in a type optional. Courtesy of
 * https://stackoverflow.com/a/54178819/2782785.
 */
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<T>;

export enum ModuleType {
    EDUCATION = 'Education',
    DEGREE_PROGRAMME = 'DegreeProgramme',
    STUDY_MODULE = 'StudyModule',
    GROUPING_MODULE = 'GroupingModule',
}

export enum AttainmentType {
    DEGREE_PROGRAMME_ATTAINMENT = 'DegreeProgrammeAttainment',
    MODULE_ATTAINMENT = 'ModuleAttainment',
    CUSTOM_MODULE_ATTAINMENT = 'CustomModuleAttainment',
    COURSE_UNIT_ATTAINMENT = 'CourseUnitAttainment',
    CUSTOM_COURSE_UNIT_ATTAINMENT = 'CustomCourseUnitAttainment',
    ASSESSMENT_ITEM_ATTAINMENT = 'AssessmentItemAttainment',
}

export enum PlanValidationState {
    INVALID = 'INVALID',
    INCOMPLETE = 'INCOMPLETE',
    PLANNED = 'PLANNED',
    APPROVED = 'APPROVED',
    APPROVED_CONDITIONALLY = 'APPROVED_CONDITIONALLY',
    APPROVAL_REQUESTED = 'APPROVAL_REQUESTED',
    APPROVAL_REQUESTED_PARTS_ATTAINED = 'APPROVAL_REQUESTED_PARTS_ATTAINED',
    APPROVED_CONDITIONALLY_PARTS_ATTAINED = 'APPROVED_CONDITIONALLY_PARTS_ATTAINED',
    APPROVED_PARTS_ATTAINED = 'APPROVED_PARTS_ATTAINED',
    PARTS_ATTAINED = 'PARTS_ATTAINED',
    ATTAINED = 'ATTAINED',
    IMPLICIT = 'IMPLICIT',
    EMPTY = 'EMPTY',
}

export enum ApprovalRequiredState {
    HAS_MODULE_CONTENT_APPROVAL_OBJECT = 'ApprovalRequiredState_Has_module_content_approval_object',
    APPROVAL_REQUIRED = 'APPROVAL_REQUIRED',
}

export interface CommonUniversitySettings {
    // The Java model has @JsonAnyGetter which merges the values of a Map (the actual setting values) as if they were
    // properties of the class itself; the TypeScript type generator can't seem to handle this
    [setting: string]: any;
}

export enum ModuleContentApprovalState {
    VALID = 'MODULECONTENTAPPROVAL_VALIDATION_STATE_VALID',
    INVALID = 'MODULECONTENTAPPROVAL_VALIDATION_STATE_INVALID',
}

/** Mirrors the identically named interface in the backend. */
export interface EntityWithApprovalState {
    approvalState: ApprovalStateType;
    documentState: DocumentState;
}

/**
 * A model for the object that FullCalendar uses to store information about a calendar event.
 * Any non-standard (https://fullcalendar.io/docs/event-object) fields we want to include have to be stored in the extendedProps hash
 * */
export interface FullCalendarEvent {
    start: Date;
    end: Date;

    backgroundColor?: string;
    borderColor?: string;
    textColor?: string;
    className?: string;
    extendedProps: {},
}

/**
 * A custom frontend model that contains data for an `Event` from a `CalendarEvent`, i.e. a custom event made by a student.
 * See calendarEvent.service.js in SIS-COMPONENTS.
 */
export interface FullCalendarCustomEvent extends FullCalendarEvent {
    extendedProps: {
        eventObject: CalendarEvent;
        cancelled?: boolean;
        eventTitle: string;

        // Only in student
        type: 'OWN_CALENDAR_EVENT';
    }
}

/**
 * A custom frontend model that contains data for an `Event` from a `StudyEvent`.
 * See fullCalendar.service.js in SIS-COMPONENTS and sisCalendar.dir.js in TEACHER.
 */
export interface FullCalendarStudyEvent extends FullCalendarEvent {
    extendedProps: {
        eventName: LocalizedString;
        notice: LocalizedString;
        studyGroupSetName: LocalizedString;
        studySubGroupName: LocalizedString;
        locationIds: OtmId[];
        locationsRemoved: boolean;
        cancelled: boolean;

        // Only in STUDENT
        type: 'CALENDAR_EVENT';
        eventTitle?: { name: LocalizedString };
        name?: LocalizedString;
        courseUnitRealisation?: CourseUnitRealisation;
        enrolment?: Enrolment;
        flowState?: FlowState;
        teacherIds?: OtmId[];

        // Only in TEACHER
        teachers?: PublicPerson[];
        teachersRemoved?: boolean;
    }
}

export interface PlanEducationOptions {
    acceptanceType: EducationOptionAcceptanceType;
    childOptionPathKey?: string;
    educationPathKey: string;
    isInPlan: boolean;
    isInPlanAsMinor: boolean;
    moduleGroupId: OtmId;
    moduleId?: OtmId;
    namingType?: Urn;
    parentModuleGroupId?: OtmId;
    phaseName: string;
    studyRightState?: StudyRightState;
}

/** A more granular flow state for course unit realisations (see courseUnitRealisation.model.js) */
export type ResolvedFlowState = 'NOT_READY' | 'PUBLISHED_ACTIVE' | 'PUBLISHED_FUTURE' | 'PUBLISHED_EXPIRED' | 'CANCELLED_ACTIVE' |
'CANCELLED_EXPIRED' | 'ARCHIVED' | 'DELETED';

/** A more granular state for enrolment rights **/
export type ResolvedEnrolmentRightState = 'ACTIVATED_FUTURE' | 'ACTIVATED_ACTIVE' | 'ACTIVATED_EXPIRED' | 'CANCELLED';

export enum RangeValidationResultState {
    IMPLICIT = 'IMPLICIT',
    MORE_REQUIRED = 'MORE_REQUIRED',
    LESS_REQUIRED = 'LESS_REQUIRED',
    IMPLICIT_OK = 'IMPLICIT_OK',
    OK = 'OK',
}

export enum RangeType {
    ONE = 'ONE',
    EXACTLY = 'EXACTLY',
    MAX = 'MAX',
    BETWEEN = 'BETWEEN',
    MIN = 'MIN',
}

export interface DecimalNumberRange {
    min: number;
    max: number;
}

export interface LocalizedBase<T> {
    [locale: string]: T;
}

export interface LocalizedMarkupString extends LocalizedBase<string> {}

export interface LocalizedString extends LocalizedBase<string> {}

export type MarkupString = string;

export interface LocalizedUrl extends LocalizedBase<string> {}

export type DurationString = string;

export type LocalDateString = string;

export type LocalDateTimeString = string;

export type LocalTimeString = string;

export interface LocalTimeRange {
    startTime: LocalTimeString;
    endTime: LocalTimeString;
    duration?: DurationString;
}

export interface LocationChangeEvent {
    location: Location;
}

export type LocalId = string;

/** String format of a Java `Money` instance. Has a three-letter ISO currency code, followed by a whitespace and the amount. */
export type MoneyString = string;

export type OtmId = string;

export type OppijaID = string;

export type PaymentId = string;

export type PersonalIdentityCode = string;

export type StudyPeriodLocator = string;

export type StudyPeriodTemplateLocator = string;

export type Urn = string;

export type ZonedDateTimeString = string;

export type OpenUniversityProductForBatchAction = OpenUniversityProduct | OpenUniversityProductResultItem;

export interface IdentityProviderLogin {
    abbr: string;
    name: LocalizedString;
    shibbolethUrl: string;
}

export interface MassExamSessionNewModalSelections {
    startTime: LocalDateTimeString;
    duration: DurationString;
    name: LocalizedString;
    responsibleOrganisations: OrganisationRoleShare[];
    locations: Location[];
}

export interface OrganisationRoleShareValidityGroupValidationErrors {
    itemsWithOrganisationIdAndEducationalInstitutionUrn: boolean;
    itemsWithoutOrganisationIdAndEducationalInstitutionUrn: boolean;
    itemsWithoutEndDate: boolean;
    itemsWithoutOrganisationId: boolean;
    itemsWithoutRoleUrn: boolean;
    itemsWithoutShare: boolean;
    itemsWithoutStartDate: boolean;
    hasDuplicates: boolean;
    hasGapBetweenValidityGroups: boolean;
    hasOverlappingValidityGroups: boolean;
    sumOfSharesNotEqualToHundred: boolean;
}

export type ResponsibilityInfo = (PersonWithModuleResponsibilityInfoType |
PersonWithCourseUnitRealisationResponsibilityInfoType |
PersonWithEducationResponsibilityInfoType) & { person: PublicPerson, role: Code };

export interface ResponsibilityInfosEditorValidationErrors {
    duplicatePersonsWithSameRole: boolean;
    itemsWithoutPersonIdOrInfoText: boolean;
    itemsWithoutRoleUrn: boolean;
    minCount: boolean;
    maxCount: boolean;
}

export interface ResponsiblePersonValidityPeriodEndDates {
    responsibleTeacherRoleEndDate?: LocalDateString;
    teacherRoleEndDate?: LocalDateString;
    adminRoleEndDate?: LocalDateString;
    contactInfoRoleEndDate?: LocalDateString;
}

export interface SearchFilterChangeEvent {
    parameterKey: string;
    values: any;
}

export interface SearchFilterToggleChildValueEvent<Value, ChildValue, Event> extends SearchFilterToggleValueEvent<Value, Event> {
    childValue: ChildValue;
}

export interface SearchFilterToggleValueEvent<Value, Event> {
    event?: Event;
    form: FormGroup;
    value: Value;
}

export enum SearchFilterType {
    MULTI = 'Multi',
    SINGLE = 'Single',
    RANGE = 'Range',
    DATE_RANGE = 'DateRange',
    SALES_PERIOD = 'SalesPeriod',
    ORGANISATION = 'Organisation',
    ORGANISATIONROOTS = 'OrganisationRoots',
    STUDY_RIGHT_ORGANISATION = 'StudyRightOrganisation',
    STUDY_RIGHT_PARENT_ORGANISATION = 'StudyRightParentOrganisation',
    EDUCATION = 'Education',
    MODULE = 'Module',
    RESPONSIBILITY_INFOS = 'ResponsibilityInfos',
    NO_POPOVER = 'NoPopover',
    MULTI_WITH_HEADINGS = 'MultiWithHeadings',
    COURSE_UNIT = 'CourseUnit',
}

export enum SearchFilterOrganisationType {
    DEFAULT = 'DEFAULT',
    STUDY_RIGHT_ORGANISATION = 'STUDY_RIGHT_ORGANISATION',
}

export interface SearchParameterOption {
    type: SearchFilterType;
    name: string;
    title?: string;
    options?: any[];
    hide?: boolean;
    disableSortByName?: boolean;
    searchDefaults?: { [name: string]: any };
}

export type OngoingRegistrationType = 'ATTENDING' | 'NONATTENDING' | 'STATUTORYABSENCE' | 'MISSING' | 'NEGLECTED' | 'FIRSTREGISTRATION' | 'EXTENSION';

/** Info related to the used and available study terms of a study right */
export interface StudyRightSemesterData {
    /** If true, the study right does not have an end date */
    neverExpires: boolean;
    /** If true, the end date of the study right is not calculated automatically, but needs to be set manually */
    setEndDateManually: boolean;
    /** The amount of unused attendance terms (term registrations with type MISSING count against this quota) */
    unused: number;
    /** The amount of used attendance terms */
    used: number;
    /** The amount of terms registrations of type ATTENDING */
    usedAttendanceTerms: number;
    /** should be used + unsused, used for translation params */
    totalAvailableSemesters: number;
    /** The amount of unused absence terms */
    absentUnused: number;
    /** The amount of used absence terms */
    absentUsed: number;
    /** should be absentUsed + absentUnused, used for translation params */
    totalAbsent: number;
    /** The amount of unused study right extension terms */
    extensionUnused: number;
    /** The amount of used study right extension terms */
    extensionUsed: number;
    /** should be extensionUsed + extensionUnused, used for translation params */
    totalExtension: number;
    /** The amount of used statutory absence terms */
    statutoryAbsentUsed: number;
    /** The amount of terms without registration */
    termsWithoutRegistration: number;
    /** True, if the study right has one or more extensions, and at least one of them has already started */
    activeExtensions: boolean;
    /** Custom type information for the term registration of the current study term (more fine-grained than `TermRegistrationType`) */
    ongoing: OngoingRegistrationType;
    /** The date when the study right would end with the current term registrations, disregarding any study right extensions */
    originalEndOfStudyRight?: moment.Moment;
    /** The first (active) study right extension that has been granted for the study right */
    firstActiveExtension?: StudyRightExtension;
}

export interface TableRowWithSubRows {
    rowId: string;
    subRowCount: number;
}

export interface TermRegistrationWithPaymentInfo extends TermRegistration {
    studentUnionMembershipFeePaid: boolean;
}

export interface UniversityConfig {
    homeOrganisations: UniversityOrganisation[];
    identityProviderLogins: IdentityProviderLogin[];
    isDebugEnabled: boolean;
    isDebugInfoEnabled: boolean;
    isInfoEnabled: boolean;
    logoutUrl: string;
    notificationsBackendUrl: string;
    officialLanguages: string[];
    studentFrontpageRedirectUrl: string;
    uiRouterTraceCategories: string[];
    /** Time in minutes to poll for service break data. Default is 5. */
    serviceBreakUpdateIntervalMinutes?: number;
    /** Url from which to load upcoming service breaks data. If left empty, will attempt to get data from the current host at '/unavailable/api/breaks'. */
    unavailableDataUrl?: string;
    /** Url from which to load next or ongoing critical service break data. If left empty, will attempt to get data from the current host at '/unavailable/api/break'. */
    criticalUnavailableDataUrl?: string;
    /**
     * URL for loading message banner data. See `MessageBannerData.java`.
     */
    messageBannerDataUrl?: string;
    idleTimeout: number;
    suomiFiShibbolethLoginUrl?: string;
    suomiFiShibbolethLogoutUrl?: string;
}

export interface UniversityOrganisation {
    id: OtmId;
    name: string;
    testOnly?: boolean;
    electronicSignatureModule?: boolean;
}

export interface UniversityPaths {
    organisationRoots: string;
    universitySettings: string;
}

export interface UniversitySettings extends GeneratedUniversitySettings {
    // The Java model has @JsonAnyGetter which merges the values of a Map (the actual setting values) as if they were
    // properties of the class itself; the TypeScript type generator can't seem to handle this
    [setting: string]: any;
}

export interface UniversityCodeBookSettings {
    codeBookSettings: CodeBookSpecificSettings;
    codesInUse: Urn[];
}

export interface SisTranslationKeyAndParams {
    translationKey: string;
    translationParams?: { [paramKey: string]: any };
}

export type SisValidationError = SisTranslationKeyAndParams;

export interface SisValidationErrors extends ValidationErrors {
    [key: string]: SisValidationError;
}

/**
 * A variant of Angular's built-in ValidatorFn interface with a more specific return type. Defines a validator function that
 * returns validation error objects that can be localized.
 */
export interface SisValidatorFn extends ValidatorFn {
    (control: AbstractControl): SisValidationErrors | null;
}

export interface SisAsyncValidatorFn extends AsyncValidatorFn {
    (control: AbstractControl): Promise<SisValidationErrors | null> | Observable<SisValidationErrors | null>;
}

export interface YearRange {
    endYear?: number;
    startYear?: number;
}

export interface Violation {
    message: string;
    messageTemplate: string;
    path: string;
    attributes: { [key: string]: unknown };
    entity: string;
    propertyPath: string;
}

export interface ConstraintViolationExplanation {
    errorType: string;
    failingIds: string[];
    violations: { [key: string]: Violation[] };
}

/**
 * EvaluationAttainmentWrapper is nicely ts-generated apart from cve-field that is a constraint violation exception from java.
 */
export interface EvaluationAttainmentWrapperWithCve extends EvaluationAttainmentWrapper {
    cve: ConstraintViolationExplanation;
}

export interface SisuLocalizedException {
    status: number;
    titleKey: string;
    messageKey: string;
    messageValue: string;
}

/**
 * A processed version of a `ConstraintViolationExplanation` that is easier to work with in the frontend.
 */
export interface ParsedConstraintViolation {
    entityKey: string;
    fieldKeys: string[];
    origPath: string;
    messageKey: string;
    attributes?: { [fieldName: string]: unknown };
}

/**
 * A processed version of the dry run results of a batch operation, providing a unified format for various error results.
 */
export interface BatchOperationError {
    messageKey?: string;
    constraintViolations?: ParsedConstraintViolation[];
}

export type CooperationNetworkValidity = 'ONGOING' | 'FUTURE' | 'PAST';

export interface EnrolmentData {
    enrolment: Enrolment;
    courseUnitRealisation: CourseUnitRealisation;
    courseUnit: CourseUnit;
}

export interface SearchOptions {
    limit: number
    start: number
    uiLang: string
    sort?: string[]
}

export interface SortOrder {
    orderKey: string
    reverse: boolean
}

export interface CommonSearchFilters {
    fullTextQuery?: string
    universityOrgId?: OtmId
}

export interface SearchParameters<T extends CommonSearchFilters = CommonSearchFilters> {
    filters: T
    options: SearchOptions
}

export interface MassExamSessionSearchFilters extends CommonSearchFilters {
    orgId?: OtmId[],
    orgRootId?: OtmId[],
    dateRange?: LocalDateRange,
}

export interface MassExamSessionQueryParams {
    fullTextQuery?: string
    dateRange?: string,
    universityOrgId?: string
    documentState?: string[]
    orgRootId?: string[]
    orgId?: string[]
    start: number
    limit: number
    sort?: string[]
}

export interface OrganisationSnapshotSearchQueryParams extends CommonSearchFilters {
    universityOrgIds?: string[]
}

export interface OpenUniversityCartSearchParams extends CommonSearchFilters {
    courseUnitGroupId?: OtmId[]
    cartState?: [string]
}
