import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UIRouterGlobals } from '@uirouter/core';
import {
    Code,
    CourseUnitResultItem,
    CurriculumPeriod,
    Organisation,
    OtmId,
    SearchFilterType,
    SearchParameterOption,
    SearchResult,
    StudyPeriod,
    StudyTerm,
    StudyYear,
} from 'common-typescript/types';
import _ from 'lodash';
import { BehaviorSubject, forkJoin, from, lastValueFrom, Observable, Subject } from 'rxjs';
import {
    map,
    mergeMap,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import { AuthService } from 'sis-common/auth/auth-service';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ModalService } from 'sis-common/modal/modal.service';
import { singleConcurrentSearchWithThrottle } from 'sis-common/search/search-utils';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import {
    COURSE_UNIT_SERVICE,
    CURRICULUM_PERIOD_SERVICE,
    SEARCH_PARAMETER_STORAGE_SERVICE,
    SEARCH_PARAMETERS,
} from 'sis-components/ajs-upgraded-modules';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { silentErrorHandler } from 'sis-components/error-handler/silent-error-handler';
import { Option } from 'sis-components/select/dropdown-select/dropdown-select.component';
import { SelectOrganisationComponent } from 'sis-components/select/select-organisation/select-organisation.component';
import { Breakpoint, BreakpointService } from 'sis-components/service/breakpoint.service';
import { CommonCodeService, IndexedCodes } from 'sis-components/service/common-code.service';
import { OrganisationEntityService } from 'sis-components/service/organisation-entity.service';
import { UniversityService } from 'sis-components/service/university.service';
import { convertAJSPromiseToNative } from 'sis-components/util/utils';

import { ORGANISATION_JS_DATA_MODEL, STUDY_PERIOD_SERVICE } from '../../ajs-upgraded-modules';
import { CourseCartEntityService } from '../../common/service/course-cart-entity.service';
import { SEARCH_CONSTANTS, SearchService } from '../search-service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-search-main',
    templateUrl: './search-main.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [SearchService],
})
export class SearchMainComponent implements OnInit, OnDestroy {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.search.search-main',
        directiveName: 'appSearchMain',
    };

    searchResult: SearchResult<CourseUnitResultItem>;
    university: Organisation;
    searchParameters = new this.SearchParameters({
        documentState: undefined,
        ignoreValidityPeriod: undefined,
        validity: 'ONGOING_AND_FUTURE',
    });

    input: string;
    noResultsMessage: string;

    currentPage = 1;
    initialQuerySort: any;

    curriculumPeriods: CurriculumPeriod[] = [];
    studyLevelUrns: Code[] = [];
    supportedAttainmentLangs: Code[] = [];
    supportedAssItemTypes: Code[] = [];
    courseCartCourseUnitIds: OtmId[] = [];
    studyPeriods: EnrichedStudyPeriod[] = [];
    studyPeriodsForSearchParameterOptions: EnrichedStudyPeriod[] = [];
    organisations: Organisation[];

    searchParams: any = {};

    tooManyResults: boolean;
    destroyed$ = new Subject<void>();
    rerunSearch$ = new BehaviorSubject(null);

    readonly languageUrn = 'urn:code:language';
    readonly assessmentItemTypeUrnExam = 'urn:code:assessment-item-type:exam';
    readonly studyLevelUrn = 'urn:code:study-level';
    readonly assessmentItemTypeUrnIndependentWork = 'urn:code:assessment-item-type:independent-work';

    searchParameterOptions: { [name: string]: SearchParameterOption } = {};
    loading = true;
    searching = false;
    isMobileView: boolean;

    activeFilters = 0;

    searchSortOptions: Option[] = [
        { label: this.translate.instant('SEARCH.SORT_SELECTOR.SORT_METHOD_OPTIONS.MATCH'), value: null },
        { label: this.translate.instant('SEARCH.SORT_SELECTOR.SORT_METHOD_OPTIONS.NAME_ASC'), value: 'name' },
        { label: this.translate.instant('SEARCH.SORT_SELECTOR.SORT_METHOD_OPTIONS.NAME_DESC'), value: '-name' },
        { label: this.translate.instant('SEARCH.SORT_SELECTOR.SORT_METHOD_OPTIONS.CREDITS_ASC'), value: 'credits' },
        { label: this.translate.instant('SEARCH.SORT_SELECTOR.SORT_METHOD_OPTIONS.CREDITS_DESC'), value: '-credits' },
    ];

    @ViewChild('textQuery') inputField: ElementRef;

    constructor(
        @Inject(COURSE_UNIT_SERVICE) private courseUnitService: any,
        @Inject(SEARCH_PARAMETERS) private SearchParameters: any,
        @Inject(CURRICULUM_PERIOD_SERVICE) private curriculumPeriodService: any,
        @Inject(STUDY_PERIOD_SERVICE) private studyPeriodService: any,
        @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,
        @Inject(SEARCH_PARAMETER_STORAGE_SERVICE) private searchParameterStorageService: any,
        @Inject(ORGANISATION_JS_DATA_MODEL) private organisationJsDataModel: any,
        private authService: AuthService,
        private uiRouterGlobals: UIRouterGlobals,
        private courseCartEntityService: CourseCartEntityService,
        private organisationEntityService: OrganisationEntityService,
        private localeService: LocaleService,
        private translate: TranslateService,
        private commonCodeService: CommonCodeService,
        private universityService: UniversityService,
        private modalService: ModalService,
        private breakpointService: BreakpointService,
        private searchService: SearchService,
        private appErrorHandler: AppErrorHandler,
    ) {
    }

    ngOnInit(): void {
        this.searchParameterStorageService.loadSearchParameterFromStorage(this.uiRouterGlobals.current.name, this.searchParameters);
        this.searchParameters.start = 0;
        this.searchParameters.limit = SEARCH_CONSTANTS.messagesPerPage;
        this.initialQuerySort = _.get(this.searchParameters, 'sort.value') || null;

        if (this.isLoggedIn()) {
            this.courseCartEntityService.getCourseCart(true)
                .pipe(
                    takeUntil(this.destroyed$),
                    this.appErrorHandler.defaultErrorHandler(),
                )
                .subscribe((ids) => this.courseCartCourseUnitIds = ids);
        }

        this.rerunSearch$
            .pipe(
                takeUntil(this.destroyed$),
                singleConcurrentSearchWithThrottle((params: any) => this.searchRequest(params)),
            )
            .subscribe((searchResult: SearchResult<CourseUnitResultItem>) => {
                this.searching = false;
                if (searchResult) {
                    this.searchResult = searchResult;
                    this.tooManyResults = this.hasTooManyResults(searchResult);
                    this.messageOnNoResults();

                    if (searchResult.start === 0) {
                        this.currentPage = 1;
                    }
                } else {
                    this.searchResult = undefined;
                }
            });

        this.initUniversity()
            .subscribe(() => {
                this.loading = false;
                this.search();
            });

        this.breakpointService.breakpoint$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(breakpoint => this.isMobileView = (breakpoint < Breakpoint.SM));
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    searchOrganisations(searchString: string) {
        let res: any[] = [];

        if (searchString.length >= 3) {
            const universityId = _.get(this.university, 'id');

            const query = {
                name: searchString,
                universityOrgId: [universityId],
            };

            this.organisationJsDataModel.findAll(query, false).then((data: any) => {
                const currentLang = this.localeService.getCurrentLanguage();
                res = _.sortBy(data, `name.${currentLang}`);

                this.searchParams.organisations.options = res.map(r => ({
                    label: this.localeService.localize(r.name),
                    value: r,
                }));
            });
        }

        this.searchParams.organisations.options = [...res];
    }

    getOrganisationById(id: string) {
        return this.organisationJsDataModel.findAll().then((organisations: any) => organisations.find((organisation: any) => organisation.id === id));
    }

    private initSearchParams() {
        const filterKeys = this.getFilterKeys();

        filterKeys.forEach((key: any) => {
            const searchParameterOptions = this.searchParameterOptions[key];
            const searchParameters = this.searchParameters[key];

            this.searchParams[key] = {
                options: this.searchService.mapSearchOptions(searchParameterOptions),
                selected: this.searchService.mapSelectedOptions(searchParameterOptions, searchParameters),
            };
        });
    }

    organisationChange(selected: any[]) {
        const values = this.searchService.mapSelectedValuesToSearchParameters(this.searchParameters.organisations, selected);
        this.searchParameters.organisations.value = [...values];

        if (selected.length === 0) {
            this.searchParameters.organisationRoots.value = [];
        }

        this.getActiveFiltersCount();
        this.getInputValue();
        this.search(0, true);
    }

    curriculumPeriodsChange(selected: any[]) {
        this.searchParameters.curriculumPeriods.value = this.searchService.mapSelectedValuesToSearchParameters(this.searchParameters.curriculumPeriods, selected);
        this.getActiveFiltersCount();
        this.getInputValue();
        this.search(0, true);
    }

    studyLevelUrnsChange(selected: any[]) {
        this.searchParameters.studyLevelUrns.value = this.searchService.mapSelectedValuesToSearchParameters(this.searchParameters.studyLevelUrns, selected);
        this.getActiveFiltersCount();
        this.getInputValue();
        this.search(0, true);
    }

    attainmentLanguageUrnsChange(selected: any[]) {
        this.searchParameters.attainmentLanguageUrns.value = this.searchService.mapSelectedValuesToSearchParameters(this.searchParameters.attainmentLanguageUrns, selected);
        this.getActiveFiltersCount();
        this.getInputValue();
        this.search(0, true);
    }

    assessmentItemTypeChange(selected: any[]) {
        this.searchParameters.assessmentItemType.value = this.searchService.mapSelectedValuesToSearchParameters(this.searchParameters.assessmentItemType, selected);
        this.getActiveFiltersCount();
        this.getInputValue();
        this.search(0, true);
    }

    curTeachingLanguageUrnsChange(selected: any[]) {
        this.searchParameters.curTeachingLanguageUrns.value = this.searchService.mapSelectedValuesToSearchParameters(this.searchParameters.curTeachingLanguageUrns, selected);
        this.getActiveFiltersCount();
        this.getInputValue();
        this.search(0, true);
    }

    studyPeriodsChange(selected: any) {
        this.searchParameters.studyPeriods.value = this.searchService.mapSelectedValuesToSearchParameters(this.searchParameters.studyPeriods, selected);
        this.getActiveFiltersCount();
        this.getInputValue();
        this.search(0, true);
    }

    getLabel(parameter: SearchParameterOption) {
        return this.translate.instant(`SEARCH.FILTER_TAGS.${parameter.name}`);
    }

    getFilterTitleLabel() {
        return this.searchService.getFilterTitleLabel(this.activeFilters);
    }

    openOrganisationSelectDialog() {
        if (!this.university) {
            throw new Error('University not loaded. Cannot open organisation select.');
        }

        const inputs = {
            university: this.university,
            organisations: this.organisations,
            selectedIds: this.searchParameters.organisations.value.map((org: any) => org.id),
            selectedParentIds: this.searchParameters.organisationRoots.value.map((org: any) => org.id),
        };
        const options = { closeWithOutsideClick: true };
        const ref = this.modalService.open(SelectOrganisationComponent, inputs, options);

        ref.result.then(({ selectedParents, selectedLeafs }) => {
            const allSelections = selectedParents.concat(selectedLeafs);
            this.organisationJsDataModel.findAll().then((organisations: any) => {
                this.searchParams.organisations.selected = allSelections.map((selectedOrganisation: any) => ({
                    label: this.localeService.localize(selectedOrganisation.name),
                    value: organisations.find(({ id }: any) => id === selectedOrganisation.id),
                }));
            });
            this.searchParameters.organisations.value = selectedLeafs;
            this.searchParameters.organisationRoots.value = selectedParents;
            this.getActiveFiltersCount();
            this.search();
        }).catch(() => {});
    }

    removeFilters() {
        const keys = this.getFilterKeys();

        keys.forEach((key: string) => {
            this.searchParameters[key].value = [];
        });

        this.searchParameters.organisationRoots.value = [];

        this.initSearchParams();
        this.getActiveFiltersCount();
        this.search();
    }

    private getFilterKeys() {
        const keys: any = [];
        _.forOwn(this.searchParameterOptions, (value, key) => { if (!value.hide) { keys.push(key); } });
        return keys;
    }

    private getActiveFiltersCount() {
        this.activeFilters = 0;
        const keys = this.getFilterKeys();

        keys.forEach((key: string) => {
            if (this.searchParameters[key]) {
                this.activeFilters += this.searchParameters[key].value.length;
            }
        });
        if (this.activeFilters === 0) {
            this.searchResult = undefined;
        }
    }

    private getInputValue() {
        this.input = this.inputField?.nativeElement?.value;
    }

    isLoggedIn(): boolean {
        return this.authService.loggedIn();
    }

    initSearchParameterOptions() {
        return forkJoin([
            this.initStudyLevelOptions(),
            this.initCurriculumPeriods(),
            this.initStudyPeriods(),
            this.initSupportedAttainmentLangs(),
            this.initSupportedAssItems(),
        ])
            .pipe(tap(() => this.populateSearchParameterOptions()),
                  tap(() => this.initSearchParams()),
                  tap(() => this.getActiveFiltersCount()));
    }

    populateSearchParameterOptions() {
        this.searchParameterOptions = {
            searchString: { name: 'SEARCHSTRING', type: SearchFilterType.NO_POPOVER, hide: true },
            organisations: { name: 'ORGANIZERS', type: SearchFilterType.ORGANISATION },
            organisationRoots: { name: 'ORGANISATIONROOTS', type: SearchFilterType.ORGANISATIONROOTS, hide: true },
            curriculumPeriods: {
                name: 'CURRICULUMPERIODS',
                options: this.curriculumPeriods,
                type: SearchFilterType.MULTI,
            },
            studyLevelUrns: {
                name: 'COURSEUNITSTUDYLEVELURNS',
                options: this.studyLevelUrns,
                type: SearchFilterType.MULTI,
            },
            attainmentLanguageUrns: {
                name: 'ATTAINMENTLANGUAGES', options: this.supportedAttainmentLangs, type: SearchFilterType.MULTI,
            },
            curTeachingLanguageUrns: {
                name: 'TEACHINGLANGUAGE', options: this.supportedAttainmentLangs, type: SearchFilterType.MULTI,
            },
            assessmentItemType: {
                name: 'ASSESSMENTITEMTYPE', options: this.supportedAssItemTypes, type: SearchFilterType.MULTI,
            },
            studyPeriods: {
                name: 'ACTIVITYPERIODS',
                options: this.studyPeriodsForSearchParameterOptions,
                type: SearchFilterType.MULTI,
            },
        };
    }

    initStudyLevelOptions(): Promise<void> {
        return this.commonCodeService.getCodebook(this.studyLevelUrn)
            .then((codeBook: IndexedCodes) => {
                this.studyLevelUrns = Object.values(codeBook);
            });
    }

    initCurriculumPeriods(): Promise<void> {
        return this.curriculumPeriodService.findByUniversityOrgId(this.university.id)
            .then((curriculumPeriods: CurriculumPeriod[]) => {
                this.curriculumPeriods = curriculumPeriods.reverse();
            });
    }

    initStudyPeriods(): Promise<void> {
        return this.studyPeriodService.getCurrentAndUpcomingStudyPeriods()
            .then((studyPeriods: EnrichedStudyPeriod[]) => {
                this.studyPeriods = _.cloneDeep(studyPeriods);
                // Set custom names
                _.forEach(studyPeriods, (studyPeriod) => {
                    studyPeriod.name = this.translate.instant('SEARCH_STUDYPERIOD_OPTION_FORMAT', {
                        periodName: this.localeService.localize(studyPeriod.name),
                        periodYear: studyPeriod.$year.name,
                    });
                });
                this.studyPeriodService.addSpecifiersToDuplicateStudyPeriodNames(studyPeriods);
                this.studyPeriodsForSearchParameterOptions = studyPeriods;
            }).catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
    }

    initSupportedAttainmentLangs(): Promise<void> {
        return lastValueFrom(from(Promise.all([
            this.commonCodeService.getCodebook(this.languageUrn),
            this.commonCodeService.getCodeBookUniversityUsage(this.languageUrn),
        ])).pipe(
            takeUntil(this.destroyed$),
            this.appErrorHandler.defaultErrorHandler(),
        ))
            .then((languages: [IndexedCodes, string[]]) => {
                const allFilters: Code[] = Object.values(languages[0]);
                const primaryFilters: Code[] = _.map(languages[1], urn => _.find(allFilters, { urn }))
                    .sort((a, b) => this.sortLanguages(a, b));

                const secondaryFilters: Code[] = _(allFilters)
                    .differenceBy(primaryFilters, 'urn')
                    .map(filterCode => _.assign({}, filterCode, { isSecondary: true }))
                    .sort((a, b) => this.sortLanguages(a, b))
                    .value();
                this.supportedAttainmentLangs = primaryFilters.concat(secondaryFilters);
            });
    }

    private sortLanguages(a: Code, b: Code) {
        return this.localeService.localize(a.name).localeCompare(this.localeService.localize(b.name));
    }

    initSupportedAssItems() {
        return lastValueFrom(from(convertAJSPromiseToNative(this.commonCodeService.getCodesByUrns([this.assessmentItemTypeUrnExam, this.assessmentItemTypeUrnIndependentWork])))
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
                takeUntil(this.destroyed$),
                map((itemTypes: Code[]) => _.cloneDeep(itemTypes)),
                mergeMap((itemTypesCopy: Code[]) => from(itemTypesCopy)
                    .pipe(
                        mergeMap((itemTypeCopy: Code) => this.searchService.translateForLocales(this.getMatchingTranslationKey(itemTypeCopy.urn), itemTypeCopy.name)),
                        map(() => itemTypesCopy),
                    )),
            ))
            .then((itemTypesCopy: Code[]) => this.supportedAssItemTypes = itemTypesCopy);
    }

    getMatchingTranslationKey(urn: string): string {
        return urn === this.assessmentItemTypeUrnIndependentWork ?
            'SEARCH.FILTER_TAGS.SHOW_ASSESSMENT_ITEM_TYPE_INDEPENDENT_WORK' :
            'SEARCH.FILTER_TAGS.SHOW_ASSESSMENT_ITEM_TYPE_EXAM';
    }

    sort(sortValue: any) {
        this.searchParameters.sort.toggleValue(sortValue);
        this.search();
    }

    addCourseUnitToCourseCart(courseUnitId: string) {
        this.courseCartEntityService.addCurToCourseCart(courseUnitId)
            .pipe(takeUntil(this.destroyed$), this.appErrorHandler.defaultErrorHandler())
            .subscribe();
    }

    removeCourseUnitFromCourseCart(courseUnitId: string) {
        this.courseCartEntityService.deleteCurFromCourseCart(courseUnitId)
            .pipe(takeUntil(this.destroyed$), this.appErrorHandler.defaultErrorHandler())
            .subscribe();
    }

    initUniversity() {
        // SearchParameters can be populated with values from sessionStorage on init. If university is found from sessionStorage use it, otherwise get current university.
        const organisationId: string = this.searchParameters?.universityOrgId?.value && this.searchParameters?.universityOrgId?.value.length > 0 ?
            this.searchParameters.universityOrgId.value[0].id :
            this.universityService.getCurrentUniversityOrgId();

        return this.organisationEntityService.findUniversityRootOrganisation(organisationId)
            .pipe(take(1), mergeMap((organisation: Organisation) => this.setUniversity(organisation)));
    }

    setUniversity(university: Organisation): Observable<any> {
        return this.setUniversityFromOrganisation(university);
    }

    setUniversitySync(university: Organisation) {
        this.setUniversityFromOrganisation(university)
            .pipe(take(1))
            .subscribe();

        this.removeFilters();
    }

    private setUniversityFromOrganisation(university: Organisation) {
        this.university = university;
        this.loadOrganisations();
        if (this.searchParameters?.universityOrgId?.value?.[0]?.id !== university.id) {
            // Only clear the organisation selections if the university was actually changed.
            // Otherwise all organisation selections would always be cleared during component initialization.
            this.searchParameters.universityOrgId.value = [{ id: university.id, name: university.name }];
            this.searchParameters.organisationRoots.value = [];
            this.searchParameters.organisations.value = [];
        }
        return this.initSearchParameterOptions();
    }

    onFullTextSearch(query: string) {
        this.getInputValue();
        this.search(0, true);
    }

    hasTooManyResults(res: SearchResult<CourseUnitResultItem>): boolean {
        return !!_.get(res, 'truncated', false);
    }

    onPaginationChange() {
        const start: number = (this.currentPage - 1) * SEARCH_CONSTANTS.messagesPerPage;
        this.search(start);
        const target = document.getElementById('results-show-guide');
        target?.focus();
    }

    searchRequest(searchParams: any): Observable<unknown> {
        return from(convertAJSPromiseToNative(this.courseUnitService.searchActive(searchParams))).pipe(silentErrorHandler);
    }

    search(start?: number, onSubmit?: boolean) {
        if (onSubmit) {
            this.searchParameters.searchString.value = this.input;
        }

        if (this.isValidQuery() && this.searchParameters.isValid()) {
            // Determines which pages courseUnits will be fetched for the view, when "messagesPerPage" -variable is set.
            this.searchParameters.start = start ? start : 0;
            this.searching = true;
            this.rerunSearch$.next(this.searchParameters);
        }
        // Save search parameters on each search-method call (Fixes OTM-26823)
        this.searchParameterStorageService.saveSearchParameterToStorage(this.uiRouterGlobals.current.name, this.searchParameters);
    }

    isValidQuery() {
        this.noResultsMessage = undefined;

        if (this.input && SEARCH_CONSTANTS.queryTextMinLength - this.input.length > 1) {
            this.noResultsMessage = this.translate.instant('SEARCH_QUERY_TEXT_TOO_SHORT_PLURAL', { chars: SEARCH_CONSTANTS.queryTextMinLength - this.searchParameters.searchString.value.length });
        } else if (this.input && SEARCH_CONSTANTS.queryTextMinLength - this.input.length === 1) {
            this.noResultsMessage = this.translate.instant('SEARCH_QUERY_TEXT_TOO_SHORT');
        } else if (this.input && this.input.length > SEARCH_CONSTANTS.queryTextMaxLength) {
            this.noResultsMessage = this.translate.instant('SEARCH_QUERY_TEXT_TOO_LONG');
        }

        if (this.noResultsMessage) {
            this.searchResult = undefined;
            return false;
        }

        return true;
    }

    messageOnNoResults() {
        this.noResultsMessage = undefined;

        if (this.searchResult?.total === 0) {
            this.noResultsMessage = this.translate.instant('SEARCH_NO_RESULTS');
        }

        if (this.tooManyResults) {
            this.noResultsMessage = this.translate.instant('SEARCH_LARGE_RESULT');
        }
    }

    get messagesPerPage() {
        return SEARCH_CONSTANTS.messagesPerPage;
    }

    get maxSize() {
        return SEARCH_CONSTANTS.maxSize;
    }

    private loadOrganisations() {
        this.organisationEntityService.findAll(this.university.id)
            .pipe(take(1), this.appErrorHandler.defaultErrorHandler())
            .subscribe(organisations => {
                this.organisations = organisations;
            });
    }
}

export interface EnrichedStudyPeriod extends StudyPeriod {
    $term: StudyTerm;
    $year: StudyYear;
}
